<template>
  <div class="shop" v-loading="isLoading">
    <!-- <div class="banner row" style="-webkit-app-region: drag;">
      <div class="title">{{ info.shopName }} --- 网吧超市</div>
    </div>
    <div
      class="close"
      @click="closeWin"
      style="-webkit-app-region: no-drag;"
    ></div> -->
    <!-- 超市 -->
    <div class="row shop-data">
      <div class="cart">
        <div class="cart-guessing"  @click="handleImagesData(bannerList[0])">
          <div v-if="bannerList.length > 0">
            <img :src="item.imgUrl"  v-for="(item,idx) in bannerList" :key="idx"/>
          </div>
        </div>
        <div class="title">购物车（{{ cart.total }}）</div>
        <div class="cart-list">
          <div
            class="cart-item row"
            v-for="(item, index) of cart.list"
            :key="index"
          >
            <img
              class="cover"
              :src="item.imgUrl || require(`@/assets/images/cover.png`)"
              alt=""
            />
            <div class="col">
              <div class="name">
                {{ item.name }}
                <label class="taste" v-if="item.tasteNames"
                  >({{ item.tasteNames }})</label
                >
              </div>
              <div>
                <label class="price">￥{{ item.price }}</label>
                <label>x{{ item.num }}</label>
              </div>
            </div>
            <div class="del" @click.stop="delCart(index)"></div>
          </div>
        </div>
        <div
          class="play active"
          v-if="cart.list.length > 0"
          @click="collection"
        >
          支付：{{cart.money}}元
        </div>
        <div class="play" v-else>支付：0元</div>
      </div>

      <div class="col goods-right">
        <div class="category row" v-loading="categoryLoading">
          <div class="arrow arrow-left" @click.stop="setActiveItem(-1)">
            <i :class="currCategoryTab > 0 ? 'active' : ''"></i>
          </div>
          <div class="category-warp">
            <el-carousel
              ref="carousel"
              height="66px"
              indicator-position="none"
              :autoplay="false"
              arrow="never"
            >
              <el-carousel-item
                v-for="(num, count) in Math.ceil(categoryList.length / 5)"
                :key="count"
                :name="`carousel` + count"
              >
                <div class="category-list">
                  <div
                    class="item"
                    :class="currCategory == index ? 'active' : ''"
                    @click="checkCategory(index)"
                    v-for="(item, index) of categoryList"
                    :key="index"
                    v-if="index >= count * 5 && index < (count + 1) * 5"
                  >
                    <img
                      class="icon"
                      :src="
                        currCategory == index
                          ? item.checkedUrl
                          : item.uncheckedUrl
                      "
                      alt=""
                    />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="arrow arrow-right" @click.stop="setActiveItem(1)">
            <i
              :class="
                currCategoryTab < Math.ceil(categoryList.length / 5) - 1
                  ? 'active'
                  : ''
              "
            ></i>
          </div>
        </div>
        <div v-loading="subCategoryLoading">
          <div class="sub-category row row-wrap">
            <div
              class="sub-warp"
              v-for="(item, index) of subCategoryList"
              :key="index"
            >
              <div
                class="sub-category-item"
                :class="currSub == index ? 'active' : ''"
                @click="checkSubCategory(index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-if="subCategoryList.length == 0" class="no-data">
              暂无类目~
            </div>
          </div>
        </div>
        <div class="goods">
          <div class="goods-search row row-center">
            <i
              class="menu-icon"
              :class="isAll ? 'active' : ''"
              @click="queryAll()"
            ></i>
            <div class="ipt col">
              <div class="row row-center">
                <el-input
                  type="text"
                  v-model="search.params.name"
                  placeholder="输入拼音简写或首字母搜索"
                  @keyup.enter.native="goodSearch"
                ></el-input>
                <i class="search-icon" @click="goodSearch"></i>
              </div>
            </div>
            <i
              class="order-icon"
              :class="search.params.isAsc == 2 ? 'tarns' : ''"
              @click="orderGoods"
            ></i>
          </div>
          <div class="tips" v-if="search.is">
            已为你搜索查询到{{ search.total }}个相关信息
          </div>
          <div class="goods-list row row-wrap" v-loading="goodsListLoading">
            <div
              class="goods-item"
              v-for="(item, index) of search.list"
              :key="index"
            >
              <div class="item-parent">
                <img
                  class="cover"
                  :src="item.imgUrl || require(`@/assets/images/cover.png`)"
                  alt=""
                  @click="handleImagesData(item)"
                />
                <div class="parent-show" v-if="item.stockCount == 0">
                  <div class="show-text">已售罄</div>
                </div>
              </div>

              <!-- <div class="cover" :style="`background:url('`+item.imgUrl+`') no-repeat center center;background-size: 100%`"></div> -->
              <div class="goods-name line-clamp-1">{{ item.name }}</div>
              <div class="row item-warp">
                <div class="price">￥{{ item.price }}</div>
                <div class="col">x1</div>
                <div
                  class="jia active"
                  v-if="item.stockCount != 0"
                  @click="checkSku(item, index)"
                >
                  +
                </div>
              </div>
            </div>
            <div
              v-if="search.list.length == 0"
              class="no-data"
              style="height:200px;"
            >
              暂无商品数据
            </div>
          </div>
          <div class="page">
            <pagination
              v-show="search.total > 0"
              :total="search.total"
              :page.sync="search.params.pageNum"
              :limit.sync="search.params.pageSize"
              @pagination="getGoodsList"
            />
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="购物车结算"
      :visible.sync="settlement.is"
      width="460px"
      top="6vh"
      class="settlement-modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="settlement-list">
        <div class="row settlement-th">
          <div class="settlement-name line-clamp-1">商品</div>
          <div class="settlement-price">单价</div>
          <div class="settlement-num">数量</div>
          <div class="settlement-one-total">金额</div>
        </div>
        <div class="settlement-td-warp">
          <div
            class="row settlement-td"
            v-for="(item, index) of settlement.list"
            :key="index"
          >
            <div class="settlement-name line-clamp-1">
              {{ item.name }}
              <label class="taste" v-if="item.tasteNames"
                >({{ item.tasteNames }})</label
              >
            </div>
            <div class="settlement-price">{{ item.price }}</div>
            <div class="settlement-num">{{ item.num }}</div>
            <div class="settlement-one-total">
              {{ (item.price * item.num).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
      <div class="sale">
        <div
          class="item row row-center"
          :class="couponModal.currType == 1 ? 'active' : ''"
          @click="openCouponModal(1)"
        >
          <div class="lb col">优惠券</div>
          <div>
            <div v-if="couponModal.currType == 1">
              <label>{{ couponModal.item.couponName }}</label>
            </div>
            <div v-else>
              <label v-if="cart.coupon.couponAmount"
                >共{{ cart.coupon.couponAmount }}个可用</label
              ><label v-else>无可用优惠券</label>/不可同活动一起使用
            </div>
          </div>
          <div class="arrow"></div>
        </div>
        <div
          class="item row row-center"
          :class="couponModal.currType == 2 ? 'active' : ''"
          @click="openCouponModal(2)"
        >
          <div class="lb col">活动</div>
          <div>
            <div v-if="couponModal.currType == 2">
              <label>{{ couponModal.item.couponName }}</label>
            </div>
            <div v-else>
              <label v-if="cart.coupon.shopActivityAmount"
                >共{{ cart.coupon.shopActivityAmount }}个可用</label
              ><label v-else>无可用活动</label>/不可同优惠券一起使用
            </div>
          </div>
          <div class="arrow"></div>
        </div>
        <div class="sale-bottom row row-center">
          <div class="col"></div>
          <div>共优惠 {{ cart.couponMoney }} 元</div>
          <div class="total">
            合计<label
              >￥{{ (cart.money - cart.couponMoney).toFixed(2) }}</label
            >
          </div>
        </div>
      </div>
      <div class="sale-remark">
        <el-input
          type="text"
          placeholder="请输入备注内容"
          size="small"
          v-model="remarkModal.cnt"
          maxlength="10"
          show-word-limit
        ></el-input>
      </div>
      <div class="payway row">
        <div
          class="payway-item"
          v-for="(item, index) of payway"
          :key="index"
          @click.stop="next(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- <div class="row btn">
                <el-button class="btn-active-inner col" @click="openRemark">点击加备注</el-button>
            </div> -->
    </el-dialog>

    <el-dialog
      title="备注"
      :visible.sync="remarkModal.is"
      width="360px"
      class="remark-modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="remark" style="margin-bottom: 30px;">
        <el-input
          ref="remark"
          type="textarea"
          placeholder="请输入备注（40个字符内）"
          v-model="remarkModal.cnt"
          maxlength="40"
          show-word-limit
          resize="none"
        />
      </div>
      <div class="row btn">
        <el-button class="btn-active col" @click.stop="remarkModal.is = false"
          >取消</el-button
        >
        <el-button
          class="btn-active-inner col"
          @click.stop="next"
          :loading="payLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="支付密码"
      :visible.sync="pwdModal.is"
      width="360px"
      class="remark-modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pwd" style="margin-bottom: 30px;">
        <el-input
          ref="pwd"
          type="password"
          placeholder="请输入支付密码"
          v-model="pwdModal.cnt"
        />
      </div>
      <div class="row btn">
        <el-button class="btn-active col" @click.stop="pwdModal.is = false"
          >取消</el-button
        >
        <el-button class="btn-active-inner col" @click.stop="pay"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="rqModal.is"
      width="460px"
      class="remark-modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      :before-close='cancelOrder'
    >
      <div class="rqcode" style="margin-bottom: 30px;">
        <p>扫一扫支付，请在3分内完成支付！</p>
        <img :src="rqModal.cnt" alt="" />
      </div>
      <div class="row btn">
        <!-- <el-button class="btn-active-inner" @click.stop="cancelOrder"
          >取消支付</el-button> -->

      </div>
    </el-dialog>

    <!-- 活动券弹框 -->
    <el-dialog
      :title="couponModal.title"
      :visible.sync="couponModal.is"
      top="6vh"
      width="300px"
      class="coupon-modal"
    >
      <div class="coupon-list">
        <div
          class="coupon-item row row-center"
          v-for="(item, index) of couponModal.list"
          :key="index"
          @click="checkCustomer(item, 3)"
        >
          <div class="col">
            <div class="row row-center">
              <div class="row row-center name">{{ item.couponName }}</div>
            </div>
            <div class="date" v-if="item.periodOfValidity">
              有效期至{{ item.periodOfValidity }}
            </div>
          </div>
          <div>
            <div class="zd-check" :class="item.checked ? 'active' : ''"></div>
          </div>
        </div>
      </div>
      <div class="row btn">
        <el-button class="btn-active-inner col" @click="couponConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 口味弹框 -->
    <el-dialog
      :title="skuModal.title"
      :visible.sync="skuModal.is"
      top="6vh"
      width="480px"
      class="sku-modal"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="sku-list">
        <div
          class="sku-item"
          v-for="(item, index) of skuModal.list"
          :key="index"
        >
          <div class="sku-name">{{ item.name }}</div>
          <div class="sku-children-list row">
            <div
              class="sku-children-item"
              :class="item.currIndex == cIndex ? 'active' : ''"
              v-for="(cItem, cIndex) of item.children"
              :key="cIndex"
              @click.stop="checkSkuChildren(item, cIndex)"
            >
              {{ cItem.name }}
            </div>
          </div>
        </div>
        <div v-if="skuModal.list && skuModal.list.length == 0" class="no-data">
          没配置口味
        </div>
      </div>
      <div class="row btn">
        <el-button class="btn-active-inner col" @click="addCart(skuModal.item)"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看大图 -->
    <div class="imgShowHide" v-show="imageShowHide"></div>
    <div class="imgDialog" v-show="imageShowHide">
      <div class="imgClose" @click="handleImgClose">
        <i class="el-icon-close"></i>
      </div>
      <img :src="imageShowUrl" />
    </div>

  </div>
</template>

<script>
import {
  getCategoryList,
  getSubCategoryList,
  getGoodsList,
  createGoodsOrder,
  payOrder,
  payStatus,
  getCouponList,
  refreshGoodsOrder,
  goodsTaste,
  goodsOrderFailure
} from "@/api/goods";
import {
  guessingList,
  callNetwork
} from "@/api/wallet";
import { getMemberInfo, memberBalance } from "@/api/member";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";

import { slideshow } from "@/api/balance/first";
export default {
  name: "Shop",
  data() {
    return {
      bannerList: [],
      // timeNowInter: null,
      // timeNow: 0,
      // messageForm: {
      //   show: false,
      //   status: false,
      //   type: 1,
      //   message: '',
      // },
      // messagePhone: false,
      // tabList:[
      //   {
      //     id: 1,
      //     name: '超市'
      //   },
      //   {
      //     id: 2,
      //     name: '竞猜'
      //   },
      //   {
      //     id: 3,
      //     name: '钱包'
      //   }
      // ],
      // tabIdx: 0,
      // guessinglist: [],
      imageShowHide: false,
      imageShowUrl: "", //查看大图地址
      isLoading: true,
      shopName: "",
      info: {},
      categoryLoading: false,
      currCategoryTab: 0,
      cart: {
        list: [],
        money: 0,
        total: 0,
        type: null,
        couponMoney: 0,
        coupon: {}
      },

      categoryList: [],

      subCategoryLoading: false,
      subCategoryList: [],
      currCategory: 0,
      currSub: 0,

      isAll: false,
      goodsListLoading: false,
      search: {
        is: false,
        val: "",
        params: {
          page: 1,
          pageSize: 8,
          isAsc: 1,
          name: ""
        },
        total: 0,
        list: []
      },
      settlement: {
        is: false,
        list: [],
        total: 0,
        money: 0
      },
      remarkModal: {
        is: false,
        cnt: ""
      },
      pwdModal: {
        is: false,
        cnt: ""
      },
      rqModal: {
        is: false,
        cnt: "",

        count: 0,
        max: 1000 * 180,
        inter: null
      },
      deductType: {
        0: "普通计费",
        1: "累计封顶",
        2: "足额预扣"
      },
      couponModal: {
        is: false,
        type: null,
        currType: null,
        title: "",
        list: [],
        item: {},
        infoDTOList: null
      },
      skuModal: {
        is: false,
        title: "",
        list: [],
        tasteIds: "",
        tasteNames: ""
      },
      currCustomer: {},
      payway: [],
      currWay: 0,
      payLoading: false,
      inter: null,
    };
  },
  methods: {
    // 关闭商品大图
    handleImgClose() {
      this.imageShowHide = false;
      this.imageShowUrl = "";
    },
    //查看大图
    handleImagesData(item) {
      this.imageShowUrl = item.showImgUrl || item.imgUrl;
      if(this.imageShowUrl) {
        this.imageShowHide = true;
      }
    },
    initData() {
      this.getCategoryList();
    },
    getCategoryList() {
      getCategoryList().then(res => {
        if (res.code == 200) {
          var list = res.data;
          this.categoryList = list;
          if (list.length > 0) {
            var categoryId = list[this.currCategory].id;
            this.getSubCategoryList(categoryId);
          }
        }
        this.categoryLoading = false;
      });
    },
    orderGoods() {
      this.search.params.isAsc = this.search.params.isAsc == 1 ? 2 : 1;
      this.getGoodsList();
    },
    goodSearch() {
      var val = this.search.params.name;
      this.isAll = false;
      if (val == "") {
        this.search.is = false;
      } else {
        this.search.is = true;
      }
      this.getGoodsList();
    },
    checkCategory(index) {
      this.isAll = false;

      this.currCategory = index;
      this.currSub = 0;
      var category = this.categoryList[index];
      this.subCategoryLoading = true;
      this.goodsListLoading = true;

      this.search.params.pageNum = 1;
      this.getSubCategoryList(category.id);
    },
    getSubCategoryList(categoryId) {
      getSubCategoryList({
        id: categoryId,
        page: 1,
        pageSize: 100
      }).then(res => {
        if (res.code == 200) {
          var list = res.data;
          this.subCategoryList = list;
          if (list.length > 0) {
            this.search.params.secondType = list[this.currSub].id;
            this.getGoodsList();
          } else {
            this.goodsListLoading = false;
            this.search.list = [];
          }
        }
        this.subCategoryLoading = false;
      });
    },
    getGoodsList() {
      var params = {};
      if (!this.isAll) {
        params = this.search.params;
        params.firstType = this.categoryList[this.currCategory].id;
        params.secondType = this.subCategoryList[this.currSub].id;
      } else {
        params.pageNum = this.search.params.pageNum;
        params.pageSize = this.search.params.pageSize;
        params.isAsc = this.search.params.isAsc;
      }
      getGoodsList(params).then(res => {
        if (res.code == 200) {
          this.search.list = res.rows;
          this.search.total = res.total;
        }
        this.goodsListLoading = false;
      });
    },
    checkSubCategory(index) {
      this.isAll = false;
      this.currSub = index;
      this.goodsListLoading = true;
      this.search.params.pageNum = 1;
      this.getGoodsList();
    },
    checkSku(item, index) {
      if (item.stockCount == 0) {
        this.$message.error("改商品已经卖完了！");
        return false;
      }
      if (this.cart.type && this.cart.type != item.type) {
        this.$message.error("普通商品和虚拟商品请分开下单！");
        return false;
      }
      Object.assign(this.$data.skuModal, this.$options.data().skuModal);
      goodsTaste({
        goodsId: item.id,
        children: true
      }).then(res => {
        if (res.code == 200) {
          var list = res.data;
          if (list.length == 0) {
            this.addCart(item);
            return false;
          }
          this.skuModal.list = list;
          this.skuModal.is = true;
          this.skuModal.title = item.name;
          this.skuModal.item = item;
        }
      });
    },
    checkSkuChildren(item, cIndex) {
      this.$set(item, "currIndex", cIndex);
    },
    addCart(item) {
      var skuList = this.skuModal.list || [],
        tasteIds = [],
        tasteNames = [];
      if (skuList.length > 0) {
        skuList.map((item, index) => {
          if (item.currIndex != null) {
            var children = item.children[item.currIndex];
            tasteIds.push(children.id);
            tasteNames.push(children.name);
          }
        });
      }
      this.cart.type = item.type;
      item.num = 1;
      item.tasteIds = tasteIds.join(",");
      item.tasteNames = tasteNames.join("/");
      this.cart.list.push(JSON.parse(JSON.stringify(item)));
      this.skuModal.is = false;
      this.bindCartParams();
    },
    delCart(index) {
      this.cart.list.splice(index, 1);
      this.bindCartParams();
    },
    bindCartParams() {
      var list = this.cart.list;
      var size = list.length;
      this.cart.totalPage = Math.ceil(size / this.cart.limt);
      if (this.cart.list.length == 0) {
        this.cart.type = null;
      }
      var money = 0,
        total = 0;
      this.cart.list.map((element, i) => {
        money += element.price * element.num;
        total += element.num;
      });
      this.cart.total = total;
      this.cart.money = money.toFixed(2);
    },
    queryAll() {
      this.isAll = !this.isAll;
      this.search.params.name = "";
      this.getGoodsList();
    },
    collection() {
      var goodsList = this.cart.list.map(element => {
        return {
          goodsId: element.id,
          goodNum: element.num
        };
      });
      var params = {
        goodsList: goodsList
      };
      getCouponList(params).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
          return false
        }

        this.settlement.is = true;
        this.payLoading = false
        this.settlement.list = JSON.parse(JSON.stringify(this.cart.list));
        var total = 0,
          money = 0;
        this.settlement.list.map(item => {
          total += item.num;
          money += item.price * item.num;
        });
        this.settlement.total = total;
        this.settlement.money = money.toFixed(2);

        this.payway = [
          { name: "微信支付", type: 5 },
          { name: "支付宝", type: 6 },
        ];
        if (this.cart.type == 1) {
          this.payway.push({ name: "上网卡支付", type: 4 });
        }
        Object.assign(this.$data.couponModal, this.$options.data().couponModal);
        this.cart.couponMoney = 0;
        this.remarkModal.cnt = "";
        this.cart.coupon = res.data;
      });
    },
    setActiveItem(index) {
      if (this.currCategoryTab == 0 && index == -1) {
        return false;
      }
      if (
        this.currCategoryTab >= Math.ceil(this.categoryList.length / 5) - 1 &&
        index == 1
      ) {
        return false;
      }
      this.currCategoryTab += index;
      this.$refs.carousel.setActiveItem(`carousel` + this.currCategoryTab);
    },
    openRemark() {
      this.remarkModal.is = true;
      this.remarkModal.cnt = "";
      this.$nextTick(() => {
        this.$refs.remark.focus();
      });
    },
    next(index) {
      this.currWay = index;
      if (this.currWay == 2 || this.currWay == 3) {
        this.pwdModal.is = true;
        this.pwdModal.cnt = "";
        this.$nextTick(() => {
          this.$refs.pwd.focus();
        });
      } else {
        this.pay();
      }
    },
    getBalance() {
      memberBalance().then(res => {
        if (res.code == 200) {
          var data = JSON.parse(decryptByDESModeCBC(res.data.data));
          this.info.availMoney = data.availMoney;
          this.info.presentedMoney = data.presentedMoney;
        }
      });
    },
    openCouponModal(type) {
      this.couponModal.list = [];
      if (type == 1) {
        if (!this.cart.coupon.couponAmount) {
          return false;
        }
        this.couponModal.title = "优惠券";
        this.couponModal.type = type;
        this.couponModal.is = true;

        var list = this.cart.coupon.couponList; //res.data.list;

        if (this.couponModal.item) {
          list = list.map(item => {
            if (this.couponModal.item.couponId == item.couponId) {
              item.checked = true;
            }
            return item;
          });
        }
        this.couponModal.list = list;
      }
      if (type == 2) {
        if (!this.cart.coupon.shopActivityAmount) {
          return false;
        }
        this.couponModal.title = "门店活动";
        this.couponModal.type = type;
        this.couponModal.is = true;

        var list = this.cart.coupon.shopActivityList; //res.data.list;

        if (this.couponModal.item) {
          list = list.map(item => {
            if (this.couponModal.item.couponId == item.couponId) {
              item.checked = true;
            }
            return item;
          });
        }
        this.couponModal.list = list;
      }
    },
    checkCustomer(item, type) {
      var checked = !item.checked;
      this.couponModal.list = this.couponModal.list.map(element => {
        element.checked = false;
        return element;
      });
      this.$set(item, "checked", checked);
    },
    couponConfirm() {
      let type = this.couponModal.type;
      var item = null;
      this.couponModal.list.map(element => {
        if (element.checked) {
          item = element;
        }
      });
      // 选择某个优惠券后计算优惠金额
      if (item) {
        var params = {
          orderMoney: this.cart.money,
          couponId: item.couponId,
          couponType: type
        };
        var infoDTOList = [];
        this.cart.list.map(element => {
          infoDTOList.push({
            goodsId: element.id,
            goodsNum: element.num
          });
        });
        params.infoDTOList = infoDTOList;

        refreshGoodsOrder(params).then(res => {
          if (res.code == 200) {
            this.cart.couponMoney = res.data.discountMoney;
            this.couponModal.currType = type;
            this.couponModal.item = item;
            this.couponModal.infoDTOList = infoDTOList;
          } else {
            this.$message.error("选择优惠券错误");
          }
          this.couponModal.is = false;
        });
      } else {
        this.cart.couponMoney = 0;
        this.$set(this.couponModal, "currType", null);
        this.couponModal.item = null;
        this.couponModal.infoDTOList = null;

        this.couponModal.is = false;
      }
    },
    pay() {
      var payType = this.payway[this.currWay].type,
        orderMoney = this.cart.money;
      var params = {
        payType: payType,
        orderMoney: orderMoney,
        actualMoney: (this.cart.money - this.cart.couponMoney).toFixed(2), // 实付金额
        discountMoney: this.cart.couponMoney, // 优惠金额
        remark: this.remarkModal.cnt || ""
      };

      params.infoDTOList = this.cart.list.map(element => {
        return {
          goodsId: element.id,
          goodsNum: element.num,
          tasteIds: element.tasteIds
        };
      });

      var infoDTOList = [];
      if (this.couponModal.currType) {
        params.couponType = this.couponModal.currType;
        params.couponId = this.couponModal.item.couponId;
      }
      if (payType == 4 || payType == 8) {
        params.password = this.pwdModal.cnt;
      }
      // return false;
      if (!this.payLoading) {
        this.payLoading = true;
        createGoodsOrder({
          data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
        }).then(res => {
          if (res.code == 200) {
            var d = res.data;
            var orderNumber = d.orderNumber;
            payOrder({
              data: encryptByDESModeCBCUtf8to64(
                JSON.stringify({
                  payType: payType,
                  orderNumber: orderNumber,
                  orderMoney: orderMoney
                })
              )
            }).then(res1 => {
              var d1 = res1.data;
              var _this = this;
              var callback = function() {
                _this.$message.success("支付成功");
                Object.assign(_this.$data.cart, _this.$options.data().cart);
                // _this.cancelOrder();
                _this.pwdModal.is = false;
                _this.remarkModal.is = false;
                _this.rqModal.is = false;
                _this.settlement.is = false;
                _this.payLoading = false;
                _this.getBalance();
              };
              if (res1.code == 200) {
                if (payType == 4 || payType == 8) {
                  callback();
                } else {
                  this.rqModal.is = true;
                  this.rqModal.cnt = d1.imgUrl;
                  this.payStatus(orderNumber, callback);
                }
                return false;
              }
              this.$message.error(res1.msg);
              this.payLoading = false;
            });
            return false;
          }
          this.payLoading = false;
          this.$message.error(res.msg);
        }).catch(() => {
          this.payLoading = false
        });
      }
    },
    cancelOrder() {
      let _this = this
      _this.$confirm('如果您正在支付中，那么请不要关闭您确定取消支付吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        center: true
      }).then(() => {
        _this.pwdModal.is = false;
        _this.remarkModal.is = false;
        _this.rqModal.is = false;
        _this.settlement.is = false;
        _this.payLoading = false;
        _this.clearInter();

      }).catch(() => {

      });
    },

    payStatus(orderNumber, callback) {
      payStatus({
        orderNumber: orderNumber
      }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
          return false;
        }
        if (res.data.status == 2) {
          this.clearInter();
          callback();
        } else {
          if (this.rqModal.count >= this.rqModal.max) {
            this.clearInter();
            Object.assign(this.$data.rqModal, this.$options.data().rqModal);
            // this.$message.error('支付超时');
            this.payLoading = false;
            // this.goodsOrderFailure(info, orderNumber);
            return false;
          }
          this.rqModal.inter = setTimeout(() => {
            this.rqModal.count += 1000;
            this.payStatus(orderNumber, callback);
          }, 1000);
        }
      });
    },
    // 支付失败
    // goodsOrderFailure(info, orderNumber) {
    //   let _this = this;
    //   goodsOrderFailure({
    //     shopId: info.shopId,
    //     orderNumber: orderNumber
    //   }).then(res1 => {
    //     if (res1.data.status == 1) {
    //       _this.$message.error("支付超时");
    //     } else {
    //       _this.$message.error("支付成功");
    //     }
    //   });
    // },
    clearInter() {
      if (this.rqModal.inter) {
        clearTimeout(this.rqModal.inter);
        this.inter = null
      }
      if (this.inter) {
        clearTimeout(this.inter);
        this.inter = null
      }
    },
    // closeWin() {
    //   if (window.Qbian) {
    //     var ipc = window.Qbian.ipc;
    //     ipc.send("window-shop-min");
    //   } else {
    //     console.log("窗体JS还未加载完毕");
    //   }
    // },

    dateDiff(startTime, endTime) {
      if (!startTime || !endTime) {
        return "";
      }
      var date1 = new Date(startTime);
      var date2 = new Date(endTime);

      var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数

      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));

      //计算相差秒数

      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      var str = "";
      if (days) {
        str += days + "天 ";
      }
      str += (hours < 10 ? "0" + hours : hours) + ":";
      str += (minutes < 10 ? "0" + minutes : minutes) + ":";
      str += seconds < 10 ? "0" + seconds : seconds;

      return str;
    },
    // 广告图
    handleGuessingList() {
      slideshow({type: 6}).then(res => {
        if (res.code == 200) {
          this.bannerList = res.data
        }
      });
    }
  },
  created() {},
  mounted() {
    this.handleGuessingList()
    this.initData();
    this.isLoading = false;
  },
  destroyed() {
    this.clearInter();

  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
